<template>
  <div class="main">
    <full-page-scroll v-model="currentPage" ref="fullPage" :size="goods.length + 1">
      <!--      第一张大图-->
      <div class="banner-item">
        <img :src="info.img" :alt="$t(info.title)" :title="$t(info.title)">
      </div>
      <!--      遍历所有商品-->
      <div class="banner-item" v-for="banner in goods">
        <img :src="banner.img" :alt="$t(banner.title)" :title="$t(banner.title)" @click="handleBannerClick(banner)">
        <div :class="`mask ${banner.style}`">
          <header-space v-if="!banner.style.startsWith('bottom')"/>
          <div class="text-wrapper">
            <div class="block1">
              <div class="type">{{ $t(banner.title) }}</div>
              <div class="name">{{ $lang(banner, "name") }}</div>
            </div>
            <div class="description">{{ $lang(banner, "description") }}</div>
          </div>
          <div class="actions-wrapper">
            <div class="action">{{ $t("main.actions.normal") }}</div>
            <div class="action">{{ $t("main.actions.custom") }}</div>
            <div class="action">{{ $t("main.actions.creative") }}</div>
          </div>
        </div>
      </div>
    </full-page-scroll>
    <!--    第一张大图的标题和操作按钮-->
    <transition-group name="fade-in-out">
      <template v-if="currentPage == 1">
        <div class="titles" key="first-title">
          <div class="title">{{ $t(info.title) }}</div>
        </div>
        <div class="actions"  key="first-actions">
          <div class="action black">{{ $t("main.actions.normal") }}</div>
          <div class="action">{{ $t("main.actions.custom") }}</div>
          <div class="action">{{ $t("main.actions.creative") }}</div>
        </div>
        <i class="el-icon-arrow-down arrow" @click="next"  key="first-arrow"></i>
      </template>
    </transition-group>
  </div>
</template>

<script>
import FullPageScroll from "@/components/FullPageScroll";
import HeaderSpace from "@/components/HeaderSpace";

export default {
  components: {HeaderSpace, FullPageScroll},
  data() {
    return {
      info: {
        title: "headerMenu.customPlatform[1]",
        img: require("@/assets/image/tsl.jpg"),
      },
      goods: [
        {
          img: require("@/assets/image/tsl.jpg"),
          title: "headerMenu.customPlatform[1]",
          style: "left",
          nameZhCn: "商品A",
          nameEnUs: "Goods Name A",
          descriptionZhCn: "在过去的几年中，交通有了很大的变化。在古代，人们习惯于乘马车旅行。这样的旅行常常是累人而乏味的。然后人们有公共汽车、火车和轮船，这样的方式可以缩短长途旅行的时间。现在我们不仅有更多的私家车，还有飞机和高铁。所有这些现代交通工具都可以为我们提供快速愉快的旅行。因此，现在越来越多的人非常喜欢旅游。总之，现代交通完全改变了我们的生活。多亏了现代交通工具，我们的世界变得越来越小。",
          descriptionEnUs: "Transportation has been greatly changed in the past few years. In ancient days, people used to travel by horse or carriage. The journey was often tiring and tedious. Then people had buses, trains and ships, which could shorten the time of the long-distance trip. Now we have not only more private cars, but also planes and high-speed rails. All of these modern transports could offer us a quick and pleasant travel. Thus, more and more people enjoy traveling very much these days. In conclusion, modern transportation has completely changed our life. Thanks to modern transportation, our world is becoming smaller and smaller.",
        },
        {
          img: require("@/assets/image/tsl2.jpg"),
          title: "headerMenu.customPlatform[2]",
          style: "right",
          nameZhCn: "商品B",
          nameEnUs: "Goods Name B",
          descriptionZhCn: "在过去的几年中，交通有了很大的变化。在古代，人们习惯于乘马车旅行。这样的旅行常常是累人而乏味的。然后人们有公共汽车、火车和轮船，这样的方式可以缩短长途旅行的时间。现在我们不仅有更多的私家车，还有飞机和高铁。所有这些现代交通工具都可以为我们提供快速愉快的旅行。因此，现在越来越多的人非常喜欢旅游。总之，现代交通完全改变了我们的生活。多亏了现代交通工具，我们的世界变得越来越小。",
          descriptionEnUs: "Transportation has been greatly changed in the past few years. In ancient days, people used to travel by horse or carriage. The journey was often tiring and tedious. Then people had buses, trains and ships, which could shorten the time of the long-distance trip. Now we have not only more private cars, but also planes and high-speed rails. All of these modern transports could offer us a quick and pleasant travel. Thus, more and more people enjoy traveling very much these days. In conclusion, modern transportation has completely changed our life. Thanks to modern transportation, our world is becoming smaller and smaller.",
        },
        {
          img: require("@/assets/image/tsl3.jpg"),
          title: "headerMenu.customPlatform[3]",
          style: "bottom-left",
          nameZhCn: "商品C",
          nameEnUs: "Goods Name C",
          descriptionZhCn: "在过去的几年中，交通有了很大的变化。在古代，人们习惯于乘马车旅行。这样的旅行常常是累人而乏味的。然后人们有公共汽车、火车和轮船，这样的方式可以缩短长途旅行的时间。现在我们不仅有更多的私家车，还有飞机和高铁。所有这些现代交通工具都可以为我们提供快速愉快的旅行。因此，现在越来越多的人非常喜欢旅游。总之，现代交通完全改变了我们的生活。多亏了现代交通工具，我们的世界变得越来越小。",
          descriptionEnUs: "Transportation has been greatly changed in the past few years. In ancient days, people used to travel by horse or carriage. The journey was often tiring and tedious. Then people had buses, trains and ships, which could shorten the time of the long-distance trip. Now we have not only more private cars, but also planes and high-speed rails. All of these modern transports could offer us a quick and pleasant travel. Thus, more and more people enjoy traveling very much these days. In conclusion, modern transportation has completely changed our life. Thanks to modern transportation, our world is becoming smaller and smaller.",
        },
        {
          img: require("@/assets/image/tsl4.jpg"),
          title: "headerMenu.customPlatform[4]",
          style: "bottom-right",
          nameZhCn: "商品D",
          nameEnUs: "Goods Name D",
          descriptionZhCn: "在过去的几年中，交通有了很大的变化。在古代，人们习惯于乘马车旅行。这样的旅行常常是累人而乏味的。然后人们有公共汽车、火车和轮船，这样的方式可以缩短长途旅行的时间。现在我们不仅有更多的私家车，还有飞机和高铁。所有这些现代交通工具都可以为我们提供快速愉快的旅行。因此，现在越来越多的人非常喜欢旅游。总之，现代交通完全改变了我们的生活。多亏了现代交通工具，我们的世界变得越来越小。",
          descriptionEnUs: "Transportation has been greatly changed in the past few years. In ancient days, people used to travel by horse or carriage. The journey was often tiring and tedious. Then people had buses, trains and ships, which could shorten the time of the long-distance trip. Now we have not only more private cars, but also planes and high-speed rails. All of these modern transports could offer us a quick and pleasant travel. Thus, more and more people enjoy traveling very much these days. In conclusion, modern transportation has completely changed our life. Thanks to modern transportation, our world is becoming smaller and smaller.",
        },
      ],
      currentPage: 1
    }
  },
  methods: {
    next() {
      this.$refs.fullPage.next();
    },
    handleBannerClick(banner) {
      let {path} = banner;
      if (path) {
        this.$router.push({path})
      }
    },
  },
}
</script>

<style scoped lang='scss'>


.main {
  height: 100vh;
  overflow-y: hidden;
}

.fullPageContainer {
  width: 100%;
  height: 100%;
  transition: all 1s ease-in-out;
}

.banner-item {
  height: 100%;
  width: 100%;
  position: relative;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    user-select: none;
  }

  .mask {
    position: absolute;
    background-color: #ffffffbb;
    padding: 50px;
    display: flex;

    .text-wrapper {
      color: #393939;

      .type {
        font-size: 16px;
      }

      .name {
        font-size: 30px;
      }

      .description {
        font-size: 14px;
      }
    }

    .actions-wrapper {

      .action {
        width: 220px;
        height: 40px;
        border: 3px solid #000000;
        border-radius: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;

        & + .action {
          margin-top: 30px;
        }
      }
    }

    &.left, &.right {
      width: 416px;
      height: 100%;
      top: 0;
      flex-direction: column;

      .text-wrapper {
        flex: 1;

        .description {
          margin-top: 10px;
        }
      }

    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    &.bottom-left, &.bottom-right {
      height: 249px;
      width: 100%;
      left: 0;
      bottom: 0;
      justify-content: center;
      align-items: flex-start;

      .text-wrapper {
        display: flex;
        width: auto;

        .description {
          max-width: 50%;
          margin-left: 62px;
        }
      }
    }

    &.bottom-left {
    }

    &.bottom-right {
      flex-direction: row-reverse;

      .actions-wrapper {
        margin-right: 110px;
      }
    }
  }
}

.titles {
  position: fixed;
  top: 158px;
  font-size: 36px;
  color: white;
  width: 100%;
  text-align: center;

  .title {
    text-shadow: 0 0 5px rgba(0, 0, 0, .15);
  }
}

.actions {
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 118px;
  justify-content: center;
  align-items: center;

  .action {
    font-size: 14px;
    background-color: rgba(255, 255, 255, .6);
    color: black;
    border-radius: 22px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    margin: 0 50px;
    cursor: pointer;
    user-select: none;

    &.black {
      background-color: rgba(0, 0, 0, .6);
      color: white;
    }
  }
}

.arrow {
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  padding: 38px 0;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}
</style>